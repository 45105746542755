import CardCategory7 from 'components/CardCategory7/CardCategory7';
import Heading from 'components/Heading/Heading';
import ModalsContainer from 'components/Modal/ModalContainer';
import StoreRequestModal from 'components/Modal/StoreRequestModal';
import MySlider from 'components/MySlider';
import { Category } from 'framework/rest/types';
import { FC, useState } from 'react';

export interface SectionSliderNewCategories2Props {
    className?: string;
    heading: string;
    subHeading: string;
    categories: Category[];
    itemPerRow?: number;
}

const SectionSliderNewCategories2: FC<SectionSliderNewCategories2Props> = ({
    heading = 'Suggestions for discovery',
    subHeading = 'Popular places to recommends for you',
    className = '',
    categories,
    itemPerRow = 5,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Category>();
    return (
        <div className={`nc-SectionSliderNewAuthors ${className}`}>
            <Heading
                desc={subHeading}
                isCenter
            >
                {heading}
            </Heading>
            <MySlider
                itemPerRow={itemPerRow}
                data={categories}
                renderItem={(item, index) => (
                    <CardCategory7
                        key={index}
                        category={item}
                        onClick={() => {
                            setIsOpen(true);
                            setSelectedItem(item);
                        }}
                    />
                )}
            />
            <ModalsContainer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            >
                <StoreRequestModal
                    item={selectedItem!}
                    setIsOpen={setIsOpen}
                />
            </ModalsContainer>
        </div>
    );
};

export default SectionSliderNewCategories2;
