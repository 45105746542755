import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

export function CloseIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.0707 15.6566L11.414 9.99981L17.0708 4.34292C17.4611 3.95268 17.4611 3.31903 17.0707 2.92866C16.6805 2.53842 16.0468 2.53842 15.6566 2.92866L9.99969 8.58556L4.3428 2.92866C3.95256 2.53842 3.31891 2.53842 2.92867 2.92866C2.5383 3.31903 2.5383 3.95268 2.92854 4.34292L8.58544 9.99981L2.92867 15.6566C2.5383 16.0469 2.5383 16.6806 2.92854 17.0708C3.31891 17.4612 3.95256 17.4612 4.34293 17.0708L9.99969 11.4141L15.6565 17.0708C16.0468 17.4612 16.6805 17.4612 17.0708 17.0708C17.4611 16.6806 17.4611 16.0469 17.0707 15.6566Z"
                fill="#564c98"
                stroke="#564c98"
                strokeWidth="0.1"
            />
        </svg>
    );
}

type ModalsContainerProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    children: ReactNode;
};

export default function ModalsContainer({ isOpen, setIsOpen, children }: ModalsContainerProps) {
    return (
        <Transition
            appear
            show={isOpen}
            as={Fragment}
        >
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto overflow-x-hidden sm:p-4"
                onClose={() => setIsOpen(false)}
            >
                <div className="min-h-screen text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 z-40 cursor-pointer bg-dark bg-opacity-60 backdrop-blur dark:bg-opacity-80" />
                    </Transition.Child>

                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-110"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-110"
                    >
                        <div className="!relative z-50 inline-block min-h-screen w-full transform overflow-hidden text-start align-middle transition-all sm:min-h-[auto] sm:w-auto">
                            <div className="relative flex min-h-screen items-center overflow-hidden sm:block sm:min-h-[auto] sm:rounded-md">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    aria-label="Close panel"
                                    className="absolute top-5 z-10 text-dark-900 outline-none transition-all hover:text-dark focus-visible:outline-none right-4 dark:text-dark-800 hover:dark:text-light-200 md:top-6 md:right-5 lg:top-7 lg:right-7"
                                >
                                    <CloseIcon className="h-4 w-4 focus-visible:outline-none lg:h-[18px] lg:w-[18px] 3xl:h-5 3xl:w-5" />
                                </button>
                                <div className="h-full w-full sm:w-auto">{children}</div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
