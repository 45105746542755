import { Dialog } from '@headlessui/react';

type DialogPopupProps = {
    isOpen: boolean;
    setIsOpen: any;
    youtubeSlug: string;
};

export default function DialogPopup({ isOpen, setIsOpen, youtubeSlug }: DialogPopupProps) {
    return (
        <Dialog
            className="fixed inset-0 z-50 overflow-y-auto"
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <div className="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80 opacity-100"></div>
            <Dialog.Panel className="min-h-screen px-1 text-center md:px-4">
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    ​
                </span>
                <div className="inline-block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 max-w-screen-lg 2xl:max-w-screen-xl opacity-100 scale-100">
                    <div className="py-4 px-6 text-center relative border-b border-neutral-100 dark:border-neutral-700 md:py-5">
                        <button
                            className="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 absolute right-2 top-1/2 transform -translate-y-1/2 sm:right-4 focus:outline-none"
                            onClick={() => setIsOpen(false)}
                        >
                            <span className="sr-only">Close</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                                data-slot="icon"
                                className="w-5 h-5"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div className="">
                        <div className="aspect-w-16 aspect-h-9 ">
                            <iframe
                                src={`https://www.youtube.com/embed/${youtubeSlug}?autoplay=1`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                title="ncblog hero video"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    );
}
